import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import TopicSubmissionTable from './TopicSubmissionTable';
import FeedbackTable from './FeedbackTable';
import ReportTable from './ReportTable';
import Header from '../../components/Header';
import { RecordFragment } from '../../gql/fragments';

const PAGE_SIZE = 100;

const GetTopicSubmissions = gql`
  query GetTopicSubmissions($first: Int, $skip: Int) {
    topicSubmissions(first: $first, skip: $skip) {
      id
      createdAt
      user {
        id
        name
      }
      title
      description
    }
  }
`;

const GetFeedbacks = gql`
  query GetFeedbacks($first: Int, $skip: Int) {
    feedbacks(first: $first, skip: $skip) {
      id
      createdAt
      user {
        id
        name
      }
      type
      description
    }
  }
`;

const GetReports = gql`
  query GetReports($first: Int, $skip: Int) {
    reports(first: $first, skip: $skip) {
      id
      type
      reporter {
        id
        name
      }
      reportee {
        id
        name
      }
      record {
        ...RecordFragment
      }
      comment {
        id
        type
        message
        url
      }
      category
      description
      createdAt
    }
  }
  ${RecordFragment}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const queries = [GetTopicSubmissions, GetFeedbacks, GetReports];
const dataKeys = ['topicSubmissions', 'feedbacks', 'reports'];
const tableComponents = [TopicSubmissionTable, FeedbackTable, ReportTable];

function Report({ history }) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const query = queries[tabIndex] || GetTopicSubmissions;
  const dataKey = dataKeys[tabIndex] || 'topicSubmissions';
  const TableComponent = tableComponents[tabIndex] || TopicSubmissionTable;

  function handleTabChange(e, v) {
    setTabIndex(v);
  }

  function goBack() {
    history.goBack();
  }

  return (
    <Container>
      <Helmet>
        <title>Report</title>
      </Helmet>
      <Header
        title="回報"
        left={
          <IconButton color="inherit" aria-label="Back" onClick={goBack}>
            <BackIcon />
          </IconButton>
        }
      >
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="話題投稿" />
          <Tab label="用戶回報" />
          <Tab label="檢舉" />
        </Tabs>
      </Header>
      <ContentContainer>
        <Query query={query} variables={{ first: PAGE_SIZE }}>
          {({ loading, error, data, fetchMore }) => {
            if (loading) {
              return null;
            }

            if (error) {
              throw error;
            }

            return (
              <TableComponent
                data={data[dataKey]}
                loadMore={page => {
                  fetchMore({
                    variables: { first: PAGE_SIZE, skip: page * PAGE_SIZE },
                    updateQuery(prev, { fetchMoreResult }) {
                      return {
                        ...prev,
                        [dataKey]: [
                          ...prev[dataKey],
                          ...fetchMoreResult[dataKey],
                        ],
                      };
                    },
                  });
                }}
              />
            );
          }}
        </Query>
      </ContentContainer>
    </Container>
  );
}

export default Report;
