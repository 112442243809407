import React from 'react';
import MuiFab from '@material-ui/core/Fab';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

function Fab(props) {
  return (
    <Container>
      <MuiFab {...props} />
    </Container>
  );
}

export default Fab;
