import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function TopicPnEditor({ topic, opened, onClose, onSubmit }) {
  const [title, setTitle] = React.useState(topic ? topic.title : '');
  const [content, setContent] = React.useState('新話題快來分享');

  function handleSubmit() {
    onSubmit({ topic, title, content });
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handleContentChange(e) {
    setContent(e.target.value);
  }

  React.useEffect(() => {
    if (topic) {
      setTitle(topic.title);
    }
  }, [topic]);

  return (
    <Dialog open={opened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {topic ? topic.title : '發PN'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              id="title"
              label="Title"
              fullWidth
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="content"
              label="Content"
              fullWidth
              value={content}
              onChange={handleContentChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          取消
        </Button>
        <Button onClick={handleSubmit} color="primary">
          送出
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TopicPnEditor;
