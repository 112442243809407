import React from 'react';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';

const columns = [
  {
    width: 120,
    label: '日期',
    dataKey: 'date',
  },
  {
    width: 120,
    label: '用戶',
    dataKey: 'user',
  },
  {
    width: 200,
    label: '標題',
    dataKey: 'title',
  },
  {
    width: 200,
    flexGrow: 1,
    label: '敘述',
    dataKey: 'description',
  },
];

const Container = styled.div`
  height: 90%;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: 56,
};

function cellRenderer({ rowData, columnIndex }) {
  let data = '';
  switch (columnIndex) {
    case 0:
      data = rowData.createdAt.substr(0, 10);
      break;
    case 1:
      data = rowData.user.name;
      break;
    case 2:
      data = rowData.title;
      break;
    case 3:
      data = rowData.description;
      break;
    default:
  }

  return (
    <TableCell component="div" variant="body" style={cellStyle}>
      {data}
    </TableCell>
  );
}

function headerRenderer({ label }) {
  return (
    <TableCell component="div" variant="head" style={cellStyle}>
      {label}
    </TableCell>
  );
}

function TopicSubmissionTable({ data }) {
  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => (
          <Paper>
            <Table
              height={height}
              width={width}
              headerHeight={56}
              rowHeight={56}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              rowStyle={{ display: 'flex' }}
            >
              {columns.map(({ dataKey, ...other }, index) => {
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={headerProps =>
                      headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    cellRenderer={cellRenderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              })}
            </Table>
          </Paper>
        )}
      </AutoSizer>
    </Container>
  );
}

export default TopicSubmissionTable;
