import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import SearchForm from './SearchForm';
import UserList from './UserList';

const ScrollContainer = styled.div`
  flex: 1;
  overflow: auto;
  padding: 30px;
  padding-bottom: 100px;
`;

function UsersContainer({
  loading,
  users,
  filters,
  searchUser,
  addFriend,
  banUser,
  unbanUser,
  onFilterChange,
}) {
  const [snackbarMessage, setSnackbarMessage] = React.useState(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [currentAction, setCurrentAction] = React.useState(null);
  const [currentActionUser, setCurrentActionUser] = React.useState({});
  const [userActionLoading, setUserActionLoading] = React.useState(false);

  const handleSearchSubmit = React.useCallback(
    q => {
      searchUser(q);
    },
    [searchUser],
  );

  const handleAddFriendCick = React.useCallback(u => {
    setAlertMessage(`你確定要跟 ${u.name} 成為好友？`);
    setCurrentAction('ADD_FRIEND');
    setCurrentActionUser(u);
  }, []);

  const handleBanUserClick = React.useCallback(u => {
    setAlertMessage(`你確定要封鎖 ${u.name}？`);
    setCurrentAction('BAN_USER');
    setCurrentActionUser(u);
  }, []);

  const handleUnbanUserClick = React.useCallback(u => {
    setAlertMessage(`你確定要解除封鎖 ${u.name}？`);
    setCurrentAction('UNBAN_USER');
    setCurrentActionUser(u);
  }, []);

  const handleSnackbarClose = React.useCallback(() => {
    setSnackbarMessage(null);
  }, []);

  const handleAlertClose = React.useCallback(() => {
    setAlertMessage(null);
  }, []);

  const handleAlertConfirm = React.useCallback(async () => {
    setUserActionLoading(true);
    setAlertMessage(null);
    const variables = { userId: currentActionUser.id };
    if (currentAction === 'ADD_FRIEND') {
      await addFriend({ variables });
      setSnackbarMessage(`已和 ${currentActionUser.name} 成為好友`);
    } else if (currentAction === 'BAN_USER') {
      await banUser({ variables });
      setSnackbarMessage(`已封鎖 ${currentActionUser.name}`);
    } else if (currentAction === 'UNBAN_USER') {
      await unbanUser({ variables });
      setSnackbarMessage(`已解除封鎖 ${currentActionUser.name}`);
    }
    setCurrentActionUser({});
    setUserActionLoading(false);
  }, [currentAction, currentActionUser, addFriend, banUser, unbanUser]);

  return (
    <ScrollContainer>
      <SearchForm
        filters={filters}
        onSearchSubmit={handleSearchSubmit}
        onFilterChange={onFilterChange}
      />
      <UserList
        loading={loading}
        users={users}
        currentActionUser={currentActionUser}
        userActionLoading={userActionLoading}
        onSearchSubmit={handleSearchSubmit}
        onAddFriendClick={handleAddFriendCick}
        onBanUserClick={handleBanUserClick}
        onUnbanUserClick={handleUnbanUserClick}
        onFilterChange={onFilterChange}
      />
      <Dialog
        open={!!alertMessage}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alertMessage || ''}</DialogTitle>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            取消
          </Button>
          <Button onClick={handleAlertConfirm} color="primary" autoFocus>
            確認
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </ScrollContainer>
  );
}

export default UsersContainer;
