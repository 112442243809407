import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

const FormContainer = styled.div`
  width: 400px;
  margin: auto;
  box-sizing: border-box;
  padding-bottom: 20px;
`;

const Input = styled(TextField)`
  width: 100%;
`;

function SearchForm({ filters, onSearchSubmit, onFilterChange }) {
  const [queryString, setQueryString] = React.useState('');

  function handleSearchSubmit() {
    onSearchSubmit(queryString);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  }

  function handleQueryStringChange(e) {
    setQueryString(e.target.value);
  }

  return (
    <FormContainer>
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={10}>
          <Input
            id="search-user-query"
            label="username"
            variant="outlined"
            onChange={handleQueryStringChange}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item xs={2}>
          <Button color="inherit" onClick={handleSearchSubmit}>
            搜尋
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={filters.isFriend}
                  onChange={() =>
                    onFilterChange({ isFriend: !filters.isFriend })
                  }
                />
              }
              label="好友"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filters.isBanned}
                  onChange={() =>
                    onFilterChange({ isBanned: !filters.isBanned })
                  }
                />
              }
              label="封鎖"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </FormContainer>
  );
}

export default SearchForm;
