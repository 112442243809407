import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddFriendIcon from '@material-ui/icons/PersonAdd';
import LockIcon from '@material-ui/icons/Lock';
import UnLockIcon from '@material-ui/icons/LockOpen';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit / 2,
  },
  friendChip: {
    backgroundColor: green[300],
  },
  bannedChip: {
    backgroundColor: red[300],
  },
});

function UserListItem({
  classes,
  user,
  currentActionUser,
  userActionLoading,
  onAddFriendClick,
  onBanUserClick,
  onUnbanUserClick,
}) {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={user.name} src={user.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={<>{user.name}</>}
        secondary={
          <>
            {user.isFriend ? (
              <Chip
                label="好友"
                color="primary"
                component="span"
                classes={{ colorPrimary: classes.friendChip }}
              />
            ) : null}
            {user.isBanned ? (
              <Chip
                label="Banned"
                color="primary"
                component="span"
                className={classes.chip}
                classes={{ colorPrimary: classes.bannedChip }}
              />
            ) : null}
          </>
        }
      />
      {user.id === currentActionUser.id && userActionLoading ? (
        <CircularProgress color="primary" />
      ) : null}
      <ListItemSecondaryAction>
        {!user.isFriend ? (
          <IconButton onClick={() => onAddFriendClick(user)}>
            <AddFriendIcon />
          </IconButton>
        ) : null}
        {user.isBanned ? (
          <IconButton onClick={() => onUnbanUserClick(user)}>
            <UnLockIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => onBanUserClick(user)}>
            <LockIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default withStyles(styles)(UserListItem);
