import React from 'react';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';

const columns = [
  {
    width: 120,
    label: '日期',
    dataKey: 'date',
  },
  {
    width: 120,
    label: '檢舉人',
    dataKey: 'reporter',
  },
  {
    width: 120,
    label: '被檢舉人',
    dataKey: 'reportee',
  },
  {
    width: 120,
    label: '類型',
    dataKey: 'category',
  },
  {
    width: 150,
    label: '原因',
    dataKey: 'reason',
  },
  {
    width: 200,
    flexGrow: 1,
    label: '細節',
    dataKey: 'detail',
  },
];

const reportCategory = {
  PROFILE: '用戶',
  NEWFEED: '故事',
  COMMENT: '留言',
};

const reportType = {
  SPAM: '騷擾訊息',
  PORN: '不雅內容',
};

const Container = styled.div`
  height: 90%;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const cellStyle = {
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: 56,
};

function cellRenderer({ rowData, columnIndex }) {
  let data = '';
  switch (columnIndex) {
    case 0:
      data = rowData.createdAt.substr(0, 10);
      break;
    case 1:
      data = rowData.reporter.name;
      break;
    case 2:
      data = rowData.reportee.name;
      break;
    case 3:
      data = reportCategory[rowData.category] || '無';
      break;
    case 4:
      if (rowData.type === 'OTHER') {
        data = rowData.description;
      } else {
        data = reportType[rowData.type] || '無';
      }
      break;
    default:
  }

  if (rowData.category === 'NEWFEED' && columnIndex === 5 && rowData.record) {
    return (
      <TableCell component="div" variant="body" style={cellStyle}>
        <a href={`http://open.firstory.me/story/${rowData.record.id}`}>
          {rowData.record.title}
        </a>
      </TableCell>
    );
  }

  return (
    <TableCell component="div" variant="body" style={cellStyle}>
      {data}
    </TableCell>
  );
}

function headerRenderer({ label }) {
  return (
    <TableCell component="div" variant="head" style={cellStyle}>
      {label}
    </TableCell>
  );
}

function ReportTable({ data }) {
  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => (
          <Paper>
            <Table
              height={height}
              width={width}
              headerHeight={56}
              rowHeight={56}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              rowStyle={{ display: 'flex' }}
            >
              {columns.map(({ dataKey, ...other }, index) => {
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={headerProps =>
                      headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    cellRenderer={cellRenderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              })}
            </Table>
          </Paper>
        )}
      </AutoSizer>
    </Container>
  );
}

export default ReportTable;
