export function padWithZero(number) {
  const string = number.toString();
  if (number < 0) {
    return '00';
  }
  if (number < 10) {
    return '0' + string;
  }
  return string;
}

export function getMMSSFromMillis(millis) {
  if (millis === null) {
    return '00:00';
  }
  const totalSeconds = millis / 1000;
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalSeconds / 60);

  return padWithZero(minutes) + ':' + padWithZero(seconds);
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
