import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-apollo';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import RecordList from './RecordList';
import SearchForm from './SearchForm';
import Header from '../../components/Header';
import { SearchStory } from '../../gql/queries';
import config from '../../config';

const PAGE_SIZE = 100;

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

function Records({ history }) {
  const classes = useStyles();
  const [queryString, setQueryString] = React.useState('');
  const [selectedRecords, setSelectedRecords] = React.useState({});
  const { loading, error, data, fetchMore } = useQuery(SearchStory, {
    variables: { queryString, first: PAGE_SIZE },
  });
  const records = data && data.searchStory ? data.searchStory : [];

  const searchStory = React.useCallback(
    q => {
      setQueryString(q);
    },
    [setQueryString],
  );

  const selectRecord = React.useCallback(
    record => {
      setSelectedRecords({
        ...selectedRecords,
        [record.id]: !selectedRecords[record.id],
      });
    },
    [selectedRecords],
  );

  const clearSelectedRecords = React.useCallback(() => {
    setSelectedRecords({});
  }, []);

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const selectedCount = React.useMemo(
    () => Object.values(selectedRecords).filter(selected => !!selected).length,
    [selectedRecords],
  );
  const selectedIds = React.useMemo(
    () =>
      Object.keys(selectedRecords)
        .filter(key => !!selectedRecords[key])
        .join(','),
    [selectedRecords],
  );
  const downloadLink = `${config.endpoints.download}?type=Record&id=${selectedIds}`;

  const loadMore = React.useCallback(
    page => {
      const skip = page * PAGE_SIZE;
      const after = records[skip - 1] ? records[skip - 1].id : null;

      if (after) {
        return fetchMore({
          variables: { first: PAGE_SIZE, after },
          updateQuery(prev, { fetchMoreResult }) {
            return {
              ...prev,
              searchStory: [...prev.searchStory, ...fetchMoreResult.searchStory],
            };
          },
        });
      }
    },
    [records, fetchMore],
  );

  if (error) {
    throw error;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Records</title>
      </Helmet>
      <Header
        title={selectedCount > 0 ? `已選取 ${selectedCount} 則` : '故事'}
        left={
          selectedCount > 0 ? (
            <IconButton
              color="inherit"
              aria-label="Close"
              onClick={clearSelectedRecords}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton color="inherit" aria-label="Back" onClick={goBack}>
              <BackIcon />
            </IconButton>
          )
        }
        right={
          selectedCount > 0 ? (
            <Button color="inherit" component="a" href={downloadLink}>
              下載
            </Button>
          ) : null
        }
      />
      <SearchForm onSearchSubmit={searchStory} />
      <RecordList
        loading={loading}
        records={records}
        selectedRecords={selectedRecords}
        selectRecord={selectRecord}
        clearSelectedRecords={clearSelectedRecords}
        goBack={goBack}
        loadMore={loadMore}
      />
    </div>
  );
}

export default Records;
