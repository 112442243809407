import React from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import AddIcon from '@material-ui/icons/Add';
import TopicListItem from './TopicListItem';
import Fab from '../../components/FloatingActionButton';
import { groupBy } from '../../utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: ${props => (props.editorOpened ? 'hide' : 'auto')};
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-bottom: 100px;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SectionTitle = styled.div`
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
`;

function TopicList({
  topicList,
  editorOpened,
  hasMore,
  loadMore,
  openeEditor,
  onItemClick,
  goBack,
}) {
  const topicsGroupByDate = groupBy(topicList, t => t.createdAt.substr(0, 10));

  return (
    <Container editorOpened={editorOpened}>
      <InfiniteScroll
        pageStart={0}
        hasMore={hasMore}
        useWindow={false}
        loadMore={loadMore}
      >
        {[...topicsGroupByDate.entries()].map(([date, topics]) => (
          <React.Fragment key={date}>
            <SectionTitle>{date}</SectionTitle>
            <Section>
              {topics.map(t => (
                <TopicListItem
                  key={t.id}
                  topic={t}
                  onClick={() => onItemClick(t)}
                />
              ))}
            </Section>
          </React.Fragment>
        ))}
      </InfiniteScroll>
      <Fab color="secondary" aria-label="Add" onClick={openeEditor}>
        <AddIcon />
      </Fab>
    </Container>
  );
}

export default TopicList;
