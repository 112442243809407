import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { GetTopics } from './queries';
import { UserFragment } from './fragments';

const PAGE_SIZE = 100;

export const LoginMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        name
        roles {
          name
        }
      }
    }
  }
`;

const CreateTopic = gql`
  mutation CreateTopic($title: String!, $image: Upload!, $tags: [String!]!) {
    createTopic(title: $title, image: $image, tags: $tags) {
      id
      title
      imageUrl
      createdAt
      tags {
        id
        tag
      }
    }
  }
`;

export function useCreateTopic() {
  const [createTopicMutation] = useMutation(CreateTopic);
  const createTopic = React.useCallback(
    ({ title, image, tags }) => {
      return createTopicMutation({
        variables: { title, image, tags },
        update(proxy, result) {
          const data = proxy.readQuery({
            query: GetTopics,
            variables: { first: PAGE_SIZE },
          });
          proxy.writeQuery({
            query: GetTopics,
            variables: { first: PAGE_SIZE },
            data: {
              ...data,
              topicList: [result.data.createTopic, ...data.topicList],
            },
          });
        },
      });
    },
    [createTopicMutation],
  );
  return [createTopic];
}

const UpdateTopic = gql`
  mutation UpdateTopic(
    $id: ID!
    $title: String
    $image: Upload
    $tags: [String!]!
  ) {
    updateTopic(id: $id, title: $title, image: $image, tags: $tags) {
      id
      title
      imageUrl
      createdAt
      tags {
        id
        tag
      }
    }
  }
`;

export function useUpdateTopic() {
  const [updateTopicMutation] = useMutation(UpdateTopic);
  const updateTopic = React.useCallback(
    ({ topicId, title, image, tags }) => {
      return updateTopicMutation({
        variables: { id: topicId, title, image, tags },
        update(proxy, result) {
          const data = proxy.readQuery({
            query: GetTopics,
            variables: { first: PAGE_SIZE },
          });
          proxy.writeQuery({
            query: GetTopics,
            variables: { first: PAGE_SIZE },
            data: {
              ...data,
              topicList: data.topicList.map(t => {
                if (t.id !== result.data.updateTopic.id) {
                  return t;
                }
                return {
                  ...t,
                  ...updateTopic,
                };
              }),
            },
          });
        },
      });
    },
    [updateTopicMutation],
  );
  return [updateTopic];
}

export const AdminTopicPn = gql`
  mutation AdminTopicPn($topicId: ID!, $title: String!, $content: String!) {
    adminTopicPn(topicId: $topicId, title: $title, content: $content)
  }
`;

export const AdminAddFriend = gql`
  mutation AdminAddFriend($userId: ID!) {
    adminAddFriend(userId: $userId) {
      id
      friend {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

export const AdminBanUser = gql`
  mutation AdminBanUser($userId: ID!) {
    adminBanUser(userId: $userId) {
      id
      isBanned
    }
  }
`;

export const AdminUnbanUser = gql`
  mutation AdminUnbanUser($userId: ID!) {
    adminUnbanUser(userId: $userId) {
      id
      isBanned
    }
  }
`;
