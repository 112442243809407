import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import TopicList from './TopicList';
import TopicEditor from './TopicEditor';
import TopicPnEditor from './TopicPnEditor';
import Header from '../../components/Header';
import { GetTopics } from '../../gql/queries';
import {
  useCreateTopic,
  useUpdateTopic,
  AdminTopicPn,
} from '../../gql/mutations';

const PAGE_SIZE = 100;

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

function Topic({ history }) {
  const classes = useStyles();
  const [editorOpened, setEditorOpened] = React.useState(false);
  const [pnEditorOpened, setPnEditorOpened] = React.useState(false);
  const [currentEdit, setCurrentEdit] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [snackbarMessage, setSnackbarMessage] = React.useState(null);
  const { networkStatus, error, data, fetchMore } = useQuery(GetTopics, {
    variables: { first: PAGE_SIZE },
  });
  const [createTopic] = useCreateTopic();
  const [updateTopic] = useUpdateTopic();
  const [adminTopicPn] = useMutation(AdminTopicPn);
  const loading = networkStatus === 1 || networkStatus === 2;

  const handleSnackbarClose = React.useCallback(() => {
    setSnackbarMessage(null);
  }, [setSnackbarMessage]);

  const handleItemClick = React.useCallback(
    item => {
      setEditorOpened(true);
      setCurrentEdit(item);
    },
    [setEditorOpened, setCurrentEdit],
  );

  const closeEditor = React.useCallback(() => {
    setEditorOpened(false);
    setCurrentEdit(null);
  }, [setEditorOpened, setCurrentEdit]);

  const openPnEditor = React.useCallback(() => {
    setPnEditorOpened(true);
  }, [setPnEditorOpened]);

  const closePnEditor = React.useCallback(() => {
    setPnEditorOpened(false);
  }, [setPnEditorOpened]);

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const loadMore = React.useCallback(
    page => {
      return fetchMore({
        variables: {
          first: PAGE_SIZE,
          skip: page * PAGE_SIZE,
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (fetchMoreResult.topicList.length === 0) {
            setHasMore(false);
          }
          return {
            ...prev,
            topicList: [...prev.topicList, ...fetchMoreResult.topicList],
          };
        },
      });
    },
    [fetchMore],
  );

  const handleSubmit = React.useCallback(
    async ({ title, image, tags }) => {
      if (currentEdit) {
        await updateTopic({ topicId: currentEdit.id, title, image, tags });
      } else {
        if (!title || !image) {
          return;
        }
        await createTopic({ title, image, tags });
      }
      closeEditor();
    },
    [currentEdit, createTopic, updateTopic, closeEditor],
  );

  if (loading) {
    return null;
  }
  if (error) {
    throw error;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Topics</title>
      </Helmet>
      <Header
        left={
          <IconButton color="inherit" aria-label="Back" onClick={goBack}>
            <BackIcon />
          </IconButton>
        }
        title="主題"
      />
      <TopicList
        topicList={data.topicList}
        editorOpened={editorOpened}
        openeEditor={() => {
          setEditorOpened(true);
        }}
        onItemClick={handleItemClick}
        hasMore={hasMore}
        loadMore={loadMore}
        goBack={goBack}
      />
      <TopicEditor
        opened={editorOpened}
        currentEdit={currentEdit}
        openPnEditor={openPnEditor}
        closePnEditor={closePnEditor}
        onSubmit={handleSubmit}
        onClose={closeEditor}
      />
      <TopicPnEditor
        topic={currentEdit}
        opened={pnEditorOpened}
        onClose={closePnEditor}
        onSubmit={async ({ topic, title, content }) => {
          await adminTopicPn({
            variables: { topicId: topic.id, title, content },
          });
          setSnackbarMessage('PN 已送出');
          closePnEditor();
        }}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}

export default Topic;
