import gql from 'graphql-tag';
import { UserFragment, RecordFragment } from './fragments';

export const GetTopics = gql`
  query GetTopics($first: Int, $skip: Int) {
    topicList(first: $first, skip: $skip) {
      id
      title
      imageUrl
      createdAt
      tags {
        id
        tag
      }
    }
  }
`;

export const SearchUser = gql`
  query SearchUser(
    $queryString: String!
    $isFriend: Boolean
    $isBanned: Boolean
    $first: Int
  ) {
    searchUser(
      queryString: $queryString
      first: $first
      isFriend: $isFriend
      isBanned: $isBanned
    ) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const SearchStory = gql`
  query SearchStory($queryString: String!, $first: Int, $after: String) {
    searchStory(queryString: $queryString, first: $first, after: $after) {
      ...RecordFragment
    }
  }
  ${RecordFragment}
`;
