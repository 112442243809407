import React, { createContext, Component, Fragment } from 'react';
import ReactPlayer from 'react-player';

const Context = createContext();

class PlayerProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idPlaying: null,
      playing: false,
    };
  }

  play = id => {
    this.setState({ idPlaying: id, playing: true });
  };

  pause = () => {
    this.setState({ playing: false });
  };

  render() {
    return (
      <Context.Provider
        value={{ ...this.state, play: this.play, pause: this.pause }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

class PlayerConsumer extends Component {
  constructor(props) {
    super(props);
    const { url } = props.data;
    this.url = [{ src: url }, { src: url, type: 'audio/mp4' }];
  }

  render() {
    const { data, render } = this.props;
    return (
      <Context.Consumer>
        {value => (
          <Fragment>
            <ReactPlayer
              playing={value.idPlaying === data.id ? value.playing : false}
              url={this.url}
              style={{ display: 'none' }}
              onEnded={() => value.pause()}
            />
            {render({
              ...value,
              playing: value.idPlaying === data.id ? value.playing : false,
              play: () => value.play(data.id),
            })}
          </Fragment>
        )}
      </Context.Consumer>
    );
  }
}

export { PlayerProvider, PlayerConsumer };
