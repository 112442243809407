import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Container = styled.div`
  position: relative;
  height: 150px;
  width: 300px;
  margin: 1px;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
`;

const Image = styled.img`
  object-fit: cover;
  height: 150px;
  width: 300px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
`;

const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
`;

const Content = styled.div`
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

function TopicListItem({ topic, onClick }) {
  const { title, imageUrl, tags } = topic;

  return (
    <Container onClick={onClick}>
      <Image src={imageUrl} />
      <Mask />
      <Content>
        <Typography variant="h6" style={{ color: 'white' }}>
          {title}
        </Typography>
        <Grid container spacing={8}>
          {tags.map(t => (
            <Grid item key={t.id}>
              <Typography variant="subtitle1" style={{ color: '#fefefe' }}>
                #{t.tag}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Content>
    </Container>
  );
}

export default TopicListItem;
