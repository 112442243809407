import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloProvider } from './components/Apollo';
import { PlayerProvider } from './components/Player';
import Routes from './Routes';
import theme from './theme';

function App() {
  return (
    <ApolloProvider>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <PlayerProvider>
            <CssBaseline />
            <Routes />
          </PlayerProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

export default App;
