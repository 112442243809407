import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import UserListItem from './UserListItem';

const useStyles = makeStyles({
  container: {
    width: 400,
    margin: 'auto',
    boxSizing: 'border-box',
  },
  loadingContainer: {
    height: 150,
  },
});

function AddFriend({
  loading,
  users = [],
  currentActionUser,
  userActionLoading,
  onSearchSubmit,
  onAddFriendClick,
  onBanUserClick,
  onUnbanUserClick,
  onFilterChange,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {loading && (
        <Grid
          className={classes.loadingContainer}
          container
          justify="center"
          alignItems="center"
        >
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading && users.length > 0 && (
        <Paper>
          <List>
            {users.map(user => (
              <UserListItem
                key={user.id}
                user={user}
                currentActionUser={currentActionUser}
                userActionLoading={userActionLoading}
                onAddFriendClick={onAddFriendClick}
                onBanUserClick={onBanUserClick}
                onUnbanUserClick={onUnbanUserClick}
              />
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
}

export default AddFriend;
