import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  container: {
    padding: 20,
    width: 500,
  },
  input: {
    width: '100%',
  },
});

function SearchForm({ onSearchSubmit }) {
  const classes = useStyles();
  const [queryString, setQueryString] = React.useState('');

  function handleSearchSubmit() {
    onSearchSubmit(queryString);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  }

  function handleQueryStringChange(e) {
    setQueryString(e.target.value);
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={10}>
          <TextField
            id="search-record-query"
            label="標題"
            variant="outlined"
            className={classes.input}
            onChange={handleQueryStringChange}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item xs={2}>
          <Button color="inherit" onClick={handleSearchSubmit}>
            搜尋
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default SearchForm;
