import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from '@material-ui/core/Chip';
import Slide from '@material-ui/core/Slide';
import Header from '../../components/Header';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 400px;
  margin: auto;
  margin-top: 50px;
  box-sizing: border-box;
`;

const Input = styled(TextField)`
  width: 100%;
`;

const Hashtag = styled(Chip)`
  margin: 4px;
`;

const ImageContainer = styled.div`
  margin-top: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 300px;
  width: auto;
  height: auto;
`;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function TopicEditor({ opened, currentEdit, openPnEditor, onSubmit, onClose }) {
  const [title, setTitle] = React.useState(
    currentEdit ? currentEdit.title : '',
  );
  const [hashtags, setTags] = React.useState(
    currentEdit && currentEdit.tags
      ? [...currentEdit.tags.map(t => t.tag)]
      : [],
  );
  const [hashtagText, setHashtagText] = React.useState('');
  const [image, setImage] = React.useState(null);

  function handleSubmit() {
    onSubmit({
      title,
      tags: hashtags,
      image: image ? image.file : null,
    });
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handleHashtagTextChange(e) {
    setHashtagText(e.target.value);
  }

  function handleHashtagAdd(tag) {
    setTags([...new Set([...hashtags, tag])]);
    setHashtagText('');
  }

  function handleHashtagDelete(index) {
    if (index >= 0 && index < hashtags.length) {
      setTags([...hashtags.slice(0, index), ...hashtags.slice(index + 1)]);
    }
  }

  function handleImageSelect(e) {
    if (e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);
      setImage({
        file: e.target.files[0],
        url,
      });
    } else {
      setImage(null);
    }
  }

  React.useEffect(() => {
    if (currentEdit) {
      setTitle(currentEdit.title);
    }
    if (currentEdit && currentEdit.tags) {
      setTags([...currentEdit.tags.map(t => t.tag)]);
    }
  }, [currentEdit]);

  return (
    <Dialog fullScreen open={opened} TransitionComponent={Transition}>
      <Container>
        <Header
          left={
            <IconButton color="inherit" aria-label="Close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          }
          right={
            <React.Fragment>
              {currentEdit ? (
                <Button color="inherit" onClick={openPnEditor}>
                  發PN
                </Button>
              ) : null}
              <Button color="inherit" onClick={handleSubmit}>
                {currentEdit ? '更新主題' : '建立主題'}
              </Button>
            </React.Fragment>
          }
        />
        <FormContainer>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Input
                id="topic-title"
                label="Title"
                value={title}
                variant="outlined"
                onChange={handleTitleChange}
              />
            </Grid>
            <Grid item xs={12}>
              {hashtags.map((tag, i) => (
                <Hashtag
                  key={tag}
                  label={tag}
                  onDelete={() => handleHashtagDelete(i)}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Input
                id="topic-hashtags"
                label="Hashtags"
                value={hashtagText}
                variant="outlined"
                onChange={handleHashtagTextChange}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleHashtagAdd(hashtagText);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={() => handleHashtagAdd(hashtagText)}>
                        新增
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={8} alignItems="center">
                <Grid item xs={8}>
                  <Input
                    id="topic-image"
                    label="Image"
                    variant="outlined"
                    value={image ? image.file.name : ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button color="inherit" component="label">
                    選擇圖片
                    <input
                      id="topic-image"
                      accept="image/*"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={handleImageSelect}
                    />
                  </Button>
                </Grid>
                <ImageContainer>
                  {image ? (
                    <Image src={image.url} />
                  ) : currentEdit ? (
                    <Image src={currentEdit.imageUrl} />
                  ) : null}
                </ImageContainer>
              </Grid>
            </Grid>
          </Grid>
        </FormContainer>
      </Container>
    </Dialog>
  );
}

export default TopicEditor;
