import React, { Component } from 'react';
import styled from 'styled-components';
import RecordCell from './RecordCell';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media (max-width: 800px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

class RecordGrid extends Component {
  render() {
    const {
      records = [],
      selectedRecords = {},
      isEdit,
      onCellClick,
    } = this.props;
    return (
      <Container>
        {records.map(record => (
          <RecordCell
            key={record.id}
            data={record}
            isEdit={isEdit}
            isSelected={selectedRecords[record.id]}
            onClick={onCellClick}
          />
        ))}
      </Container>
    );
  }
}

export default RecordGrid;
