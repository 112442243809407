import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { Home, Records, Topics, Reports, Users, Login } from './pages';

const privateRoutes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/records',
    component: Records,
  },
  {
    path: '/topics',
    component: Topics,
  },
  {
    path: '/reports',
    component: Reports,
  },
  {
    path: '/users',
    component: Users,
  },
];

function Routes({ isAuthenticated }) {
  return (
    <Fragment>
      <Helmet>
        <title>Firstory Admin</title>
      </Helmet>
      <Switch>
        {privateRoutes.map(r => (
          <PrivateRoute
            key={r.path}
            exact
            path={r.path}
            component={r.component}
            isAuthenticated={isAuthenticated}
          />
        ))}
        <Route exact path="/login" component={Login} />
      </Switch>
    </Fragment>
  );
}

export default Routes;
