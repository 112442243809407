import React from 'react';
import styled from 'styled-components';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import UncheckIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { PlayerConsumer } from './Player';
import { getMMSSFromMillis } from '../utils';

const Container = styled.div`
  height: 150px;
  position: relative;
  cursor: ${props => (props.isEdit ? 'pointer' : 'default')};
  @media (max-width: 600px) {
    height: 100px;
  }
`;

const Album = styled.img`
  width: 100%;
  height: 100%;
  background-color: #131313;
  object-fit: cover;
`;

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Title = styled.div`
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding-right: 30px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const Author = styled.div`
  color: #aaa;
  font-size: 14px;
  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

const Duration = styled.div`
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  color: white;
  background-color: #555;
  font-size: 10px;
  @media (max-width: 500px) {
    font-size: 8px;
  }
`;

const ToggleContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const PlayPauseContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function RecordCell({ data, isEdit, isSelected, onClick }) {
  const [hover, setHover] = React.useState(false);

  function handleMouseEnter() {
    setHover(true);
  }

  function handleMouseLeave() {
    setHover(false);
  }

  function handleClick() {
    if (onClick) {
      onClick(data);
    }
  }

  const { title, imageUrl, author, duration } = data;
  return (
    <Container
      isEdit={isEdit}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PlayerConsumer
        data={data}
        render={({ playing, play, pause }) => (
          <>
            <Album src={imageUrl} />
            <InfoContainer>
              <Title>{title}</Title>
              <Author>{author.name}</Author>
              <Duration>{getMMSSFromMillis(duration)}</Duration>
            </InfoContainer>
            {isEdit && (
              <ToggleContainer>
                {isSelected ? (
                  <CheckIcon style={{ color: '#ec5151', fontSize: 25 }} />
                ) : hover ? (
                  <CheckIcon style={{ color: '#aaa', fontSize: 25 }} />
                ) : (
                  <UncheckIcon style={{ color: '#aaa', fontSize: 25 }} />
                )}
              </ToggleContainer>
            )}
            <PlayPauseContainer>
              {playing ? (
                <PauseIcon
                  style={{ color: 'white', fontSize: 50 }}
                  onClick={e => {
                    e.stopPropagation();
                    pause();
                  }}
                />
              ) : (
                <PlayIcon
                  style={{ color: 'white', fontSize: 50 }}
                  onClick={e => {
                    e.stopPropagation();
                    play();
                  }}
                />
              )}
            </PlayPauseContainer>
          </>
        )}
      />
    </Container>
  );
}

export default RecordCell;
