import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import RecordGrid from './RecordGrid';
import { groupBy } from '../utils';

const Section = styled.div`
  padding-bottom: 30px;
`;

const SectionTitle = styled.div`
  margin: 10px 0;
`;

const Date = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const Count = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 10px;
`;

class RecordGridWithDate extends Component {
  render() {
    const { records, isEdit, selectedRecords, onCellClick } = this.props;
    const recordsGroupByDate = groupBy(records, record =>
      record.createdAt.substr(0, 10),
    );

    return (
      <Fragment>
        {[...recordsGroupByDate.entries()].map(([date, records]) => (
          <Section key={date}>
            <SectionTitle>
              <Date>{date}</Date>
              <Count>{records.length} 則</Count>
            </SectionTitle>
            <RecordGrid
              isEdit={isEdit}
              records={records}
              selectedRecords={selectedRecords}
              onCellClick={onCellClick}
            />
          </Section>
        ))}
      </Fragment>
    );
  }
}

export default RecordGridWithDate;
