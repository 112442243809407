import React from 'react';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const Title = styled(Typography)`
  flex: 1;
`;

const Header = ({ title, left, right, children }) => (
  <div>
    <AppBar position="static">
      <Toolbar>
        {left}
        <Title variant="h6" color="inherit">
          {title}
        </Title>
        {right}
      </Toolbar>
      {children}
    </AppBar>
  </div>
);

export default Header;
