const Configs = {
  dev: {
    endpoints: {
      simple: 'http://localhost:4000',
      file: 'http://localhost:4000/upload',
      download: 'http://localhost:4000/download',
    },
    firebase: {
      apiKey: 'AIzaSyANhw7kEbm7_CS1l1cEZybYeFb7EdDFuI4',
      authDomain: 'firstory-dev-5a8e8.firebaseapp.com',
      databaseURL: 'https://firstory-dev-5a8e8.firebaseio.com',
      projectId: 'firstory-dev-5a8e8',
      storageBucket: 'firstory-dev-5a8e8.appspot.com',
      messagingSenderId: '93689108236',
    },
    ga: 'UA-113063265-3',
  },
  prod: {
    endpoints: {
      simple: 'https://backend.endpoints.firstory-709db.cloud.goog',
      file: 'https://backend.endpoints.firstory-709db.cloud.goog/upload',
      download: 'https://backend.endpoints.firstory-709db.cloud.goog/download',
    },
    firebase: {
      apiKey: 'AIzaSyAhUYupQ-NV4okBivsxci49V4pi4CA3wxE',
      authDomain: 'firstory-709db.firebaseapp.com',
      databaseURL: 'https://firstory-709db.firebaseio.com',
      projectId: 'firstory-709db',
      storageBucket: 'firstory-709db.appspot.com',
      messagingSenderId: '1052149404106',
    },
    ga: 'UA-113063265-3',
  },
};

const config =
  process.env.NODE_ENV === 'development' ? Configs.dev : Configs.prod;

export default config;
