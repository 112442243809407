import gql from 'graphql-tag';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    name
    avatar
    isFriend
    isBanned
  }
`;

export const RecordFragment = gql`
  fragment RecordFragment on Record {
    id
    title
    createdAt
    author {
      id
      name
    }
    url
    imageUrl
    duration
  }
`;
