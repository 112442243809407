import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Users from './Users';
import Header from '../../components/Header';
import { SearchUser } from '../../gql/queries';
import {
  AdminAddFriend,
  AdminBanUser,
  AdminUnbanUser,
} from '../../gql/mutations';

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

function UsersContainer({ history }) {
  const classes = useStyles();
  const [queryString, setQueryString] = React.useState('');
  const [filters, setFilters] = React.useState({
    isFriend: false,
    isBanned: false,
  });
  const { loading, data } = useQuery(SearchUser, {
    variables: {
      queryString,
      isFriend: filters.isFriend,
      isBanned: filters.isBanned,
      first: 20,
    },
  });

  const [adminAddFriend] = useMutation(AdminAddFriend);
  const [adminBanUser] = useMutation(AdminBanUser);
  const [adminUnbanUser] = useMutation(AdminUnbanUser);

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const searchUser = React.useCallback(
    q => {
      setQueryString(q);
    },
    [setQueryString],
  );

  const handleFilterChange = React.useCallback(
    f => {
      setFilters({
        ...filters,
        ...f,
      });
    },
    [filters, setFilters],
  );

  const users = data && data.searchUser ? data.searchUser : [];

  return (
    <div className={classes.container}>
      <Helmet>
        <title>用戶</title>
      </Helmet>
      <Header
        title="用戶"
        left={
          <IconButton color="inherit" aria-label="Back" onClick={goBack}>
            <BackIcon />
          </IconButton>
        }
      />
      <Users
        loading={loading}
        users={users}
        filters={filters}
        searchUser={searchUser}
        addFriend={adminAddFriend}
        banUser={adminBanUser}
        unbanUser={adminUnbanUser}
        onFilterChange={handleFilterChange}
      />
    </div>
  );
}

export default UsersContainer;
