import React from 'react';
import { ApolloProvider as RCTApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { concat } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import config from '../config';

const ApolloContext = React.createContext();

const localStorageToken = localStorage.getItem('token');

function ApolloProvider({ children }) {
  const [token, setToken] = React.useState(localStorageToken);

  const onAuthChange = React.useCallback(token => {
    setToken(token);
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, []);

  const client = React.useMemo(() => {
    const httpLink = createUploadLink({
      uri: config.endpoints.simple,
    });
    const authMiddleware = setContext(() => {
      const authHeader = token ? `Bearer ${token}` : null;
      return {
        headers: {
          authorization: authHeader,
        },
      };
    });
    const client = new ApolloClient({
      link: concat(authMiddleware, httpLink),
      cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
    });
    return client;
  }, [token]);

  const value = React.useMemo(
    () => ({
      client,
      isAuthenticated: !!token,
      onAuthChange,
    }),
    [client, token, onAuthChange],
  );

  return (
    <RCTApolloProvider client={client}>
      <ApolloContext.Provider value={value}>{children}</ApolloContext.Provider>
    </RCTApolloProvider>
  );
}

export { ApolloProvider, ApolloContext };
