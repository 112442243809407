import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfiniteScroll from 'react-infinite-scroller';
import RecordGridWithDate from '../../components/RecordGridWithDate';

const useStyles = makeStyles({
  container: {
    overflow: 'auto',
    padding: 30,
    paddingBottom: 100,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 150,
  },
});

function RecordList({
  loading,
  records,
  selectedRecords,
  selectRecord,
  clearSelectedRecords,
  goBack,
  loadMore,
}) {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <InfiniteScroll
        pageStart={0}
        hasMore
        useWindow={false}
        loadMore={loadMore}
      >
        <RecordGridWithDate
          isEdit
          records={records}
          selectedRecords={selectedRecords}
          onCellClick={selectRecord}
        />
      </InfiniteScroll>
    </div>
  );
}

export default RecordList;
