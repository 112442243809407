import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { ApolloContext } from '../../components/Apollo';
import Header from '../../components/Header';
import { LoginMutation } from '../../gql/mutations';

const useStyles = makeStyles({
  container: {
    marginTop: 30,
  },
  input: {
    width: 300,
  },
  buttonContainer: {
    marginTop: 10,
  },
});

function Login({ location }) {
  const classes = useStyles();
  const { onAuthChange } = React.useContext(ApolloContext);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const [login] = useMutation(LoginMutation);

  const { from } = location.state || { from: { pathname: '/' } };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  return (
    <>
      <Header title="Login" />
      <div className={classes.container}>
        <Grid container justify="center">
          <TextField
            id="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            margin="normal"
            variant="outlined"
            className={classes.input}
          />
        </Grid>
        <Grid container justify="center">
          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
            variant="outlined"
            className={classes.input}
          />
        </Grid>
        <Grid container justify="center" className={classes.buttonContainer}>
          <Button
            color="inherit"
            onClick={async () => {
              const {
                data: {
                  login: { token, user },
                },
              } = await login({ variables: { email, password } });
              if (user && user.roles.find(r => r.name === 'Admin')) {
                onAuthChange(token);
                setRedirectToReferrer(true);
              }
            }}
          >
            Login
          </Button>
        </Grid>
      </div>
    </>
  );
}

export default Login;
