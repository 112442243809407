import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ApolloContext } from '../components/Apollo';
import Header from '../components/Header';

const ListContainer = styled(Grid)`
  margin-top: 30px;
`;

const LogoutButtonContainer = styled(Grid)`
  margin-top: 10px;
`;

const routes = [
  {
    to: '/records',
    title: '故事',
  },
  {
    to: '/topics',
    title: '主題',
  },
  {
    to: '/users',
    title: '用戶',
  },
  {
    to: '/reports',
    title: '回報',
  },
];

function ListItemLink(props) {
  return <ListItem button component={Link} {...props} />;
}

function Home() {
  const { onAuthChange } = React.useContext(ApolloContext);

  return (
    <>
      <Header title="Firstory Admin" />
      <ListContainer container justify="center">
        <Grid item xs={12} sm={4}>
          <Paper>
            <List component="nav">
              {routes.map(r => (
                <ListItemLink key={r.to} to={r.to}>
                  <ListItemText primary={r.title} />
                </ListItemLink>
              ))}
            </List>
          </Paper>
        </Grid>
      </ListContainer>
      <LogoutButtonContainer container justify="center">
        <Button
          color="secondary"
          onClick={() => {
            onAuthChange(null);
          }}
        >
          Logout
        </Button>
      </LogoutButtonContainer>
    </>
  );
}

export default Home;
